import React from "react";
import Navigation from "./Navigation";
import MobileNavigation from "./MobileNavigation";
import "../Navbar/styles/TopBar.scss";

function TopBar() {
  return (
    <div className="top-bar">
      <div className="topbar-initial">F. D.</div>
      <Navigation />
      <MobileNavigation />
    </div>
  );
}

export default TopBar;
