import React from "react";
import { motion } from "framer-motion";
import "./styles/TopBar.scss";

function NavLinks(props) {
  const animateForm = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };

  return (
    <ul className="links">
      <motion.li
        initial={animateForm}
        animate={animateTo}
        transition={{ delay: 0.05 }}
        onClick={() => props.isMobile && props.closeMobileMenu()}
      >
        <a href="#home-page" className="link">
          Home
        </a>
      </motion.li>
      <motion.li
        initial={animateForm}
        animate={animateTo}
        transition={{ delay: 0.1 }}
        onClick={() => props.isMobile && props.closeMobileMenu()}
      >
        <a href="#about-me" className="link">
          About Me
        </a>
      </motion.li>
      <motion.li
        initial={animateForm}
        animate={animateTo}
        transition={{ delay: 0.2 }}
        onClick={() => props.isMobile && props.closeMobileMenu()}
      >
        <a href="#projects" className="link">
          Projects
        </a>
      </motion.li>
      <motion.li
        initial={animateForm}
        animate={animateTo}
        transition={{ delay: 0.3 }}
        onClick={() => props.isMobile && props.closeMobileMenu()}
      >
        <a href="#testimonials" className="link">
          Testimonials
        </a>
      </motion.li>
      <motion.li
        initial={animateForm}
        animate={animateTo}
        transition={{ delay: 0.4 }}
        onClick={() => props.isMobile && props.closeMobileMenu()}
      >
        <a
          href="https://drive.google.com/file/d/1TQFU59rw6gXw84wcBFlZYqcixpQrmd6-/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          CV
        </a>
      </motion.li>
      <motion.li
        initial={animateForm}
        animate={animateTo}
        transition={{ delay: 0.5 }}
        onClick={() => props.isMobile && props.closeMobileMenu()}
      >
        <a href="#contact-me" className="link">
          Contact Me
        </a>
      </motion.li>
    </ul>
  );
}

export default NavLinks;
