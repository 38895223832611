import React, { useState } from "react";
import wsiApp from "../../images/webp/wsi-ss.webp";
import nasaApp from "../../images/webp/nasa-image-tool-ss-3.webp";
import infoIcon from "../../images/info-icon.svg";
import githubIcon from "../../images/github-icon.svg";
import openInNewIcon from "../../images/open-in-new.svg";
import "../../styles/Projects.scss";

const projects = {
  nasa_app: "nasa_app",
  wsi: "wsi",
};

function MobileProjects() {
  const [showProjectInfo, setShowProjectInfo] = useState("");

  const handleInfoClick = (project) => {
    setShowProjectInfo(showProjectInfo === project ? "" : project);
  };
  return (
    <section className="mobile-projects-container">
      <div className="project-wrapper">
        <div className="project-name-wrapper">
          <h3 className="project-name">WSI Noise Tool</h3>
          <img
            src={infoIcon}
            alt="the letter i inside of a circle."
            className="project-info-icon"
            onClick={() => handleInfoClick(projects.wsi)}
          />
        </div>
        {showProjectInfo === projects.wsi && (
          <div className="project-info">
            <p className="info-paragraph">
              This application is a noise tool that was developped for the
              Australian Government, addressing the concerns of residents near
              the airport. The tool enables users to input their address or
              select a map point, providing insights into anticipated flight
              frequency and noise pollution levels. It incorporates visual aids
              like flight videos and generates custom PDF reports for a detailed
              overview. This resource aims to empower residents with information
              about the potential noise impact associated with the development
              and operation of the Western Sydney International Airport.
              Furhermore, There is no link for the code because it is
              confidential.
            </p>
            <p className="info-paragraph">
              <strong>Technologies used:</strong>
              <div>React CSS</div>
            </p>
          </div>
        )}
        <div className="project-img-wrapper">
          <img
            className="project-img"
            src={wsiApp}
            alt="a screenshot from WSI noise tool"
          />
        </div>
        <div className="project-links">
          <a
            href="https://wsiflightpaths.aerlabs.com/"
            target="_blank"
            rel="noreferrer"
            className="project-live-link"
          >
            Live Demo
            <img
              src={openInNewIcon}
              alt="An arrow facing and crossing the right sight of an rectangle"
              className="project-link-icon"
            />
          </a>
        </div>
      </div>
      <div className="project-wrapper">
        <div className="project-name-wrapper">
          <h3 className="project-name">NASA image tool</h3>
          <img
            src={infoIcon}
            alt="the letter i inside of a circle."
            className="project-info-icon"
            onClick={() => handleInfoClick(projects.nasa_app)}
          />
        </div>
        {showProjectInfo === projects.nasa_app && (
          <div className="project-info">
            {/* <hr className="info-hr"></hr> */}
            <p className="info-paragraph">
              NASA Image Tool is an application that uses a 3rd-party NASA Open
              API to display images related to the user's search.
            </p>
            <p className="info-paragraph">
              <strong>Technologies used:</strong>
              <div>React CSS</div>
            </p>
          </div>
        )}
        <div className="project-img-wrapper">
          <img
            className="project-img"
            src={nasaApp}
            alt="react techinal test app"
          />
        </div>
        <div className="project-links">
          <a
            href="https://nasa-image-tool.netlify.app/"
            target="_blank"
            rel="noreferrer"
            className="project-live-link"
          >
            Live Demo
            <img
              src={openInNewIcon}
              alt="An arrow facing and crossing the right sight of an rectangle"
              className="project-link-icon"
            />
          </a>
          <a
            href="https://github.com/Feridun12/React-technical-testing"
            target="_blank"
            rel="noreferrer"
            className="project-github-link"
          >
            Code
            <img
              src={githubIcon}
              alt="The icon of Github.com. Which is basically a cat figurine with one octopus arm."
              className="project-link-icon"
            />
          </a>
        </div>
      </div>
    </section>
  );
}

export default MobileProjects;
