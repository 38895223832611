import React from "react";
import avatar from "../images/avatar.png";
import linkedin from "../images/linkedin-logo.png";
// import arrowSymbol from "../images/arrow-symbol.png";
import "../styles/Testimonials.scss";

function Testimonials() {
  return (
    <div className="testimonials" id="testimonials">
      <h1 className="page-title">Testimonials</h1>
      <div className="testimonials-container">
        <section className="card-container">
          <div className="card">
            <div className="testimonial-top">
              <img
                className="user-pic"
                src={avatar}
                alt="personal pic of the person"
              />
              <a
                href="https://www.linkedin.com/in/erselaker/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="testimonial-linkedin"
                  src={linkedin}
                  alt="linkedin logo"
                />
              </a>
            </div>
            <div className="testimonial-center">
              Feridun has been a star student during his software development
              bootcamp; always going the extra mile with his innate skill and
              curiosity. He is a fast learner and methodical in his thinking. He
              would make a great addition to any engineering team.
            </div>
            <div className="testimonial-bottom">
              <h3 className="testimonial-name">Ersel Aker</h3>
              <h4 id="testimonial-title">Founder of CyprusCodes</h4>
            </div>
          </div>
        </section>
        <section className="card-container">
          <div className="card">
            <div className="testimonial-top">
              <img
                className="user-pic"
                src={avatar}
                alt="personal pic of the person"
              />
              <a
                href="https://www.linkedin.com/in/rpfkoster/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="testimonial-linkedin"
                  src={linkedin}
                  alt="linkedin logo"
                />
              </a>
            </div>
            <div className="testimonial-center">
              Feridun has demonstrated a solid understanding and comfort with
              JavaScript and React, which has been a valuable asset to our
              projects. His proficiency has consistently contributed to our
              team's success. Moreover, he willingly shares his knowledge,
              benefitting the team's growth. Beyond his skills, Feridun fosters
              a positive work environment with calm and composed demeanor,
              optimistic attitude and collaborative spirit. He is a team player
              who goes the extra mile to help his team members where he can.
            </div>
            <div className="testimonial-bottom">
              <h3>Robert Koster</h3>
              <h4 id="testimonial-title">Founder of AerLabs</h4>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Testimonials;
