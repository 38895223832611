import React, { useEffect, useRef } from "react";
import "../styles/Intro.scss";
import { init } from "ityped";
import { CgArrowDownR } from "react-icons/cg";

function Intro() {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: false,
      backDelay: 1000,
      backSpeed: 50,
      cursorChar: "|",
      strings: [" a Frontend Developer", " an Economist", " a Tech Enthusiast"],
    });
  }, []);
  return (
    <div className="intro" id="home-page">
      <div className="intro-wrapper">
        <h1 className="intro-name">Feridun Djanselen</h1>
        <h3 id="intro-h3">
          I am&nbsp;<div ref={textRef}></div>
        </h3>
        <section className="arrow-sign">
          <a href="#about-me" id="arrow-sign">
            <CgArrowDownR
              className="intro-arrow-sign"
              color="white"
              size="40px"
            />
          </a>
        </section>
      </div>
    </div>
  );
}
export default Intro;
