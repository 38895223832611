import React from "react";
import MobileProjects from "../tools/projects/MobileProjects";
import DesktopProjects from "../tools/projects/DesktopProjects";
// styling imports
import "../styles/Projects.scss";

function Projects() {
  return (
    <div className="projects-wrapper" id="projects">
      <h1 className="page-title">Projects</h1>
      <MobileProjects />
      <DesktopProjects />
    </div>
  );
}

export default Projects;
