import React from "react";
import profile from "../images/webp/profil2_copy.webp";
import "../styles/AboutMe.scss";

function AboutMe() {
  return (
    <div className="about-me" id="about-me">
      <h1 className="page-title">About me</h1>
      <section className="about-me-content-wrapper">
        <div className="about-me-left-side">
          <div className="about-me-profile-pic-wrapper">
            <img
              className="profile-pic"
              src={profile}
              alt="a pic of me in the about part"
            />
          </div>
        </div>
        <div className="about-me-right-side">
          <div className="about-me-info-wrapper">
            <p className="about-me-info">Hi there! I'm Feridun 👋</p>
            <p className="about-me-info">
              I am a Cypriot Junior Frontend Developer specializing in React and
              Javascript. My journey into programming began after completing my
              Bachelor's in Economics, where I started pursuing after finance
              and economics-related jobs. Many of these positions required
              proficiency in programming languages like SQL for database
              interaction.
            </p>
            <p className="about-me-info">
              To further develop my skills in this area, I made the decision to
              enroll in a software development bootcamp, where I could learn
              programming under professional guidance. This experience led me to
              discover my passion for web development and pursue a careeer in
              web development.
            </p>
            <p className="about-me-info">
              Upon creating my own starter website, featuring my name and a link
              to my LinkedIn page, I recognized the infinite opportunities
              within the programming sector. The process of website creation
              provided a sense of freedom and individuality; each website has a
              unique identity, making no two alike!
            </p>
            <p className="about-me-info">
              Since then, I have worked as a junior frontend developer and
              honing my skills in React and Javascript and also been learning
              Typescript on the side. Currently, I am actively seeking a
              full-time position where I can contribute to a company's success
              while advancing toward my goal of becoming a Senior Frontend
              Developer. I am eager to collaborate with and learn from
              experienced senior developers.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutMe;
