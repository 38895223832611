import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import linkedin from "../images/webp/linkedin-logo.webp";
import github from "../images/webp/github-logo.webp";
import "../styles/ContactMe.css";

function ContactMe() {
  const formRef = useRef();
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_qaig6p9",
        "template_k3ffr3n",
        formRef.current,
        "EwDY51O29PTZwJTqB"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  // done ? "I will contact you as soon as possible!" : "cannot submit at the moment.";
  // console.log(done);
  return (
    <div className="contact-me" id="contact-me">
      <h1 className="page-title">Contact me</h1>
      <div className="contact-content-wrapper">
        <div className="contact-left-side">
          <div className="contact-details">
            <h2 className="contact-detail-title">Let's work together!</h2>
            <div className="contact-detail">
              Mail: canselenferidun@gmail.com
            </div>
            <div className="contact-detail">Telephone: +31 64 85 996 45</div>
          </div>
          <div className="dsk-social-media-logo-wrapper">
            <a
              className="soc-med-link"
              href="https://www.linkedin.com/in/feridun-canselen-73666b200/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-media-logo"
                src={linkedin}
                alt="linkedin-logo"
              />
            </a>
            <a
              className="soc-med-link"
              href="https://github.com/Feridun12"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-media-logo"
                src={github}
                alt="linkedin-logo"
              />
            </a>
          </div>
        </div>
        <div className="contact-right-side">
          <div className="contact-me-form">
            <form
              className="contact-form"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <h3 className="contact-form-title">Reach out to me! </h3>
              <input
                className="contact-input"
                type="text"
                placeholder="Name"
                name="user_name"
                required={true}
              />
              <input
                className="contact-input"
                type="text"
                placeholder="Subject"
                name="user_subject"
                required={true}
              />
              <input
                className="contact-input"
                type="email"
                placeholder="Example@hotmail.com"
                name="user_email"
                required={true}
              />
              <textarea
                id="contact-message-area"
                rows="5"
                placeholder="Your message"
                name="message"
              />
              <div className="contact-me-btn-wrapper">
                <button className="glow-on-hover">Submit</button>
              </div>
              {done && (
                <div className="submit-message">
                  I will contact you as soon as possible!
                </div>
              )}
            </form>
          </div>
          <div className="social-media-wrapper">
            <h3 className="social-media-title">React out to me!</h3>
            <div className="social-media-logo-wrapper">
              <a
                className="soc-med-link"
                href="https://www.linkedin.com/in/feridun-canselen-73666b200/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-media-logo"
                  src={linkedin}
                  alt="linkedin-logo"
                />
              </a>
              <a
                className="soc-med-link"
                href="https://github.com/Feridun12"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="social-media-logo"
                  src={github}
                  alt="linkedin-logo"
                />
              </a>
              {/* <a
              className="soc-med-link"
              href="https://twitter.com/FCanselen"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-media-logo"
                src={twitter}
                alt="twitter-logo"
              />
            </a> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contact-footer">
        <h3 className="footer-trademark">
          Designed and built by Feridun Djanselen
        </h3>
      </div> */}
    </div>
  );
}

export default ContactMe;
